<template>
    <div v-if="pdState.info" class="el-content">
        <div class="plugin-detail" v-if="step == 0 ">
            <div class="plugin-info">
                <img :src="pdState.info.plugin_logo" alt="">
                <div class="pi-right">
                    <div class="name">{{ pdState.info.plugin_name }}</div>
                    <div class="desc">{{ pdState.info.plugin_desc }}</div>
                    <a-space>
                        <a-button type="primary"
                                  v-if="pdState.info.is_enable"
                                  @click="toManager(pdState.info.plugin_sign)">管理</a-button>
                        <a-button type="primary" v-else @click="step = 1">立即购买</a-button>
                        <a-button @click="$router.go(-1)">返回</a-button>
                    </a-space>
                </div>
            </div>
            <div class="pd-title">详细介绍</div>
            <div class="pl-desc" v-html="pdState.info.content"></div>
        </div>

        <div v-if="step == 1 " class="plugin-buy">
            <div class="plugin-buy-con">
                <img class="pb-logo" :src="pdState.info.plugin_logo" alt="">
                <div class="pb-info">
                    <div class="pb-info-title">插件名称：</div>
                    <div class="pb-info-val">{{ pdState.info.plugin_name }}</div>
                </div>
                <div class="pb-info">
                    <div class="pb-info-title">插件描述：</div>
                    <div class="pb-info-val">{{ pdState.info.plugin_desc }}</div>
                </div>
                <div class="pb-info">
                    <div class="pb-info-title">购买价格：</div>
                    <div class="pb-info-val price" v-if="pdState.info.amount_actual">{{  pdState.info.amount_actual }}元</div>
                    <div class="pb-info-val" v-else>免费</div>
                </div>
                <div class="pb-info" v-if="pdState.info.amount_actual > 0">
                    <div class="pb-info-title">支付方式：</div>
                    <div >
                        <com-select-pay-method @success="e=>pbState.form.pay_type = e"></com-select-pay-method>
                    </div>
                </div>
                <div class="pb-info">
                    <div class="pb-info-title"></div>
                    <div class="pb-info-val">
                        <a-space>
                            <a-button type="primary" @click="createPluginOrder">
                                {{ pdState.info.amount_actual > 0 ? '立即购买':'立即开通'}}
                            </a-button>
                            <a-button @click="step = 0">取消</a-button>
                        </a-space>
                    </div>
                </div>
            </div>

        </div>

        <div class="cpr-success" v-if="step == 2">
            <div class="cpr-success-icon">
                <div class="icon"><i class="ri-check-line"></i></div>
                <span>支付成功</span>
            </div>
            <p class="cpr-tips">您的订单 {{ pbState.order_sn }} 已支付成功!</p>
            <a-button type="primary" @click="backRefresh">返回查看</a-button>
        </div>
        <div v-if="pbState.show">
            <com-wxpay-model
                :money="pdState.info.amount_actual"
                :qrcode="pbState.wx_pay_qrcode"
                @paied="checkIsPay"
                @close="pbState.show = false"></com-wxpay-model>
        </div>

    </div>

</template>

<script>
import { usePluginDetail,usePluginBuy } from '@/models/plugin'
import {useRoute} from "vue-router";
import router from "@/router";
import store from "@/store";
import {ref} from "vue";
import comSelectPayMethod from "@/components/pay/com-select-pay-method"
import  comWxpayModel from '@/components/pay/com-wxpay-modal'
export default {
    components:{
        comSelectPayMethod,
        comWxpayModel
    },
    setup(){
        let step = ref(0)  //0展示插件详情 1购买下单
        let options = useRoute().query

        if( options.out_trade_no ){
            step.value = 2
            // state.order_number = options.out_trade_no
        }
        let { pdState } = usePluginDetail(options.id)

        let { pbState,createPluginOrder,checkIsPay,refreshPlugin } = usePluginBuy(pdState)

        function toManager(sign){
            let nav = JSON.parse(localStorage.getItem('miniapp_nav_key'))
            let first = nav.first , second = nav.second , third = nav.third ,path = ""
            switch (sign){
                case 'shop':
                    path = '/goods/list'
                    first = "/goods/list"
                    second = "/goods/list"
                    break;
                case 'sales_share':
                    first = "/marketing/couponList"
                    second = "/adhibition/distribution/list"
                    path = '/adhibition/distribution/list'
                    break;
                case 'multi_merchant':
                    first = "/adhibition/store/set"
                    second = "/adhibition/store/set"
                    path = '/adhibition/store/set'
                    break;
                case 'wholesale':
                    first = "/marketing/couponList"
                    second = "/wholesale/goodsList"
                    path = '/wholesale/goodsList'
                    break;
                case 'salesroom':
                    first = "/marketing/couponList"
                    second = "/adhibition/outlet/list"
                    path = '/adhibition/outlet/list'
                    break;
                case 'return_to_cash':
                    first = "/marketing/couponList"
                    second = "/marketing/cashback/list"
                    path = '/marketing/cashback/list'
                    break;
                case 'marketing_9_lock_draw':
                    first = "/marketing/couponList"
                    second = "/marketing/lottery/set"
                    path = '/marketing/lottery/set'
                    break;
                case 'shop_card':
                    first = "/marketing/couponList"
                    second = "/adhibition/card/list"
                    path = '/adhibition/card/list'
                    break;
                case 'event':
                    first = "/marketing/couponList"
                    second = "/adhibition/active/list"
                    path = '/adhibition/active/list'
                    break;
                case 'live_broadcast':
                    first = "/marketing/couponList"
                    second = "/adhibition/streaming/index"
                    path = '/adhibition/streaming/index'
                    break;
                case 'web_site':
                    first = "/website/pagelist"
                    second = "/website/basicset"
                    path = '/website/basicset'
                    break;
                case 'pointsMall':
                    first = "/marketing/couponList"
                    second = "/adhibition/integral/type"
                    path = '/adhibition/integral/type'
                    break;
                case 'spike':
                    first = "/marketing/couponList"
                    second = "/adhibition/seckill/list"
                    path = '/adhibition/seckill/list'
                    break;
                case 'land_give':
                    first = "/marketing/couponList"
                    second = "/adhibition/freeLand/event"
                    path = '/adhibition/freeLand/event'
                    break;
                case 'land_exchange':
                    first = "/land/landList"
                    second = "/land/change"
                    path = '/land/change'
                    break;
                case 'community':
                    first = "/marketing/couponList"
                    second = "/adhibition/forum/list"
                    path = '/adhibition/forum/list'
                    break;
                case 'land_game':
                    first = "/land/landList"
                    second = "/adhibition/landCustom/operate"
                    path = '/adhibition/landCustom/operate'
                    break;
                case 'land_2d':
                    first = "/land/landList"
                    second = "/adhibition/landCustom/operate"
                    path = '/adhibition/landCustom/operate'
                    break;
                case 'land':
                    first = "/land/landList"
                    second = "/land/landList"
                    path = '/land/landList'
                    break;
                case 'adopt':
                    first = "/adopt/list"
                    second = "/adopt/list"
                    path = '/adopt/list'
                    break;
                default:
                    break;

            }

            let newNav = { first,second,third }
            store.dispatch('setMiniNav', newNav);
            localStorage.setItem("miniapp_nav_key",JSON.stringify(newNav))
            router.push(path)
        }

        function backRefresh(){
            step.value = 0
            router.push({path:'/plugin/detail',query:{id:options.id}})
        }

        return{step, pdState,toManager,pbState,createPluginOrder,checkIsPay,refreshPlugin,backRefresh}
    }
}
</script>

<style lang="scss">

// 插件详情
.plugin-detail{
    .plugin-info{
        width: 100%;
        display: flex;
        border-bottom: 1px solid #f4f4f4;
        padding: 12px 0 24px 0;

        img{
            width: 100px;
            height: 100px;
            margin-right: 12px;
        }
        .name{
            font-weight: bold;
            font-size: 18px;
        }
        .desc{
            font-size: 14px;
            color: #999;
            margin: 12px 0;
        }

    }
    .pd-title{
        margin: 12px 0;
        font-size: 15px;

    }
    .pl-desc{
        img{
            width: 100%;
        }
    }
}


.plugin-buy{
    width: 100%;
    height: 600px;
    margin-top: 48px;
    text-align: center;
    padding-bottom: 48px;
    &-con{
        width: 700px;
        height: 500px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
    .pb-logo{
        width: 120px;
        height: 120px;
        margin-bottom: 24px;
    }

    .pb-info{
        display: flex;
        min-height: 48px;
        align-items: center;

        &-title{
            width: 250px;
            text-align: right;
            color: #666;
            font-size: 12px;
        }

        &-val{
            width: 450px;
            text-align: left;
            font-size: 15px;
            font-weight: bold;
        }

        &:last-child{
            margin-top: 32px;
        }
    }
    .price{
        color: red;
    }

}


.cpr-success{
    width: 100%;
    text-align: center;
    padding-top: 48px;

    &-icon{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #00CC66;
            color: #fff;
            line-height: 40px;
            text-align: center;

            i{
                font-size: 24px;
            }
        }
        span{
            margin-left: 12px;
            font-size: 18px;
            color: #000;
        }
    }

    .cpr-tips{
        margin: 24px 0;
        color: #666;
    }
}

</style>
